'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';

export const LinkedInScriptTag = () => {
  const [isProd, setIsProd] = useState(false);
  useEffect(() => {
    setIsProd(process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV === 'production');
  }, []);

  if (!isProd) {
    return null;
  }

  return (
    <>
      {/* Linkedin tracking script */}
      <Script type='text/javascript'>
        {`_linkedin_partner_id = "6528748";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
      </Script>
      <Script type='text/javascript'>
        {`(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </Script>
    </>
  );
};
